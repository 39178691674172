import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";
import View from "./View";

const Startup = () => {
  const Main = () => {
    return (
      <div className="font-poppins">
        {View.map((item) => (
          <div key={item.id} className="h-fit pb-12 md:pb-[50px]">
            <h1 className="text-black py-3 sm:py-5 pl-3 sm:pl-5 text-[25px] sm:text-[35px] md:text-[45px] font-black">
              {item.head}
            </h1>
            <div className="flex flex-col md:flex-row items-start md:items-center">
              <p className="text-black font-semibold px-3 sm:px-5 text-justify mt-3 sm:mt-[25px] w-full md:w-[600px]">
                {item.content}
              </p>
              <img
                src={item.img}
                alt=""
                className="ml-0 md:ml-3 mt-3 md:mt-0 h-[200px] w-[250px] sm:h-[250px] sm:w-[300px] rounded-xl"
              />
            </div>
            <Link to={item.link}>
              <button className="flex group mt-3">
                <h1 className="text-black hover:bg-white hover:text-black border-[2px] border-white font-bold ml-3 sm:ml-5 px-3 py-2 rounded-xl">
                  Read More ...
                </h1>
              </button>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen w-full">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-24 sm:pt-32 lg:pt-[120px]">
        <div className="flex justify-center">
          <Main />
        </div>
      </div>
      {/* <div className="relative top-[106px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Startup;
