import React, { useState } from "react";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";
import address from "../img/company.png";
import gmail from "../img/email.png";
import phone from "../img/phone.png";
import social from "../img/social.png";
import insta from "../img/instagram.png";
import lin from "../img/linkedin.png";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceId = 'service_lfnidws';
    const templateId = 'template_q66ypgf';
    const publicKey = 'RLBix6f-D8DkDV6sz';
    
    const templateParams = {
      from_name: name,
      from_email: email,
      from_phone: phoneNum,
      to_name: "entreovert",
      message: message,
    };

    emailjs.init(publicKey);

    emailjs.send(serviceId, templateId, templateParams)
      .then((res) => {
        console.log("Email Sent Successfully", res);
        setName('');
        setEmail('');
        setPhoneNum('');
        setMessage('');
      })
      .catch((err) => {
        console.error("Error Sending Email", err);
      });
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 font-poppins md:h-full h-[1800px] min-h-screen w-full">
      <div className="pt-[100px] lg:pt-[200px]">
        <h1 className="text-black text-[30px] lg:text-[40px] font-bold mb-8 lg:mb-16 text-center flex justify-center lg:text-left">Contact Us</h1>
        <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row lg:justify-center">
          <div className="bg-gray-400 p-5 lg:h-[580px] lg:w-[480px] mx-5 lg:ml-10 rounded-[30px] flex flex-col justify-center">
            <div>
              <div className="py-2 lg:pt-10 lg:pl-10">
                <label className="text-white text-[16px] lg:text-[20px] font-semibold">Name:</label>
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Your Full Name"
                  className="rounded-[5px] mt-2 lg:mt-5 pl-3 h-8 w-full lg:w-[400px] text-[13px] text-black font-bold"
                />
              </div>
              <div className="py-2 lg:pt-5 lg:pl-10">
                <label className="text-white text-[16px] lg:text-[20px] font-semibold">Email Id:</label>
                <br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Your Email Id"
                  className="rounded-[5px] mt-2 lg:mt-5 pl-3 h-8 w-full lg:w-[400px] text-[13px] text-black font-bold"
                />
              </div>
              <div className="py-2 lg:pt-5 lg:pl-10">
                <label className="text-white text-[16px] lg:text-[20px] font-semibold">Phone no:</label>
                <br />
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={(e) => setPhoneNum(e.target.value)}
                  value={phoneNum}
                  placeholder="Your Phone no"
                  className="rounded-[5px] mt-2 lg:mt-5 pl-3 h-8 w-full lg:w-[400px] text-[13px] text-black font-bold"
                />
              </div>
              <div className="py-2 lg:pt-5 lg:pl-10">
                <label className="text-white text-[16px] lg:text-[20px] font-semibold">Message:</label>
                <br />
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  placeholder="Write Something.."
                  className="rounded-[5px] mt-2 lg:mt-5 pl-3 pt-2 h-[80px] w-full lg:w-[400px] text-[13px] text-black font-bold"
                ></textarea>
                <div>
                <button className="px-6 py-3 bg-purple-600 text-white font-semibold rounded-lg  ml-24 mt-3 mb-3 shadow-md hover:bg-purple-700 transition-all duration-300" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 lg:ml-10 lg:flex lg:justify-center">
            <iframe
              className="w-full lg:w-[650px] h-[300px] lg:h-[400px] rounded-[30px]"
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.618034972305!2d79.1376437!3d12.9619632!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad47418c8ec02f%3A0xbe86b5bb7ecb8c7d!2sEntreovert%20People%20Association!5e0!3m2!1sen!2sin!4v1715411805041!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </form>
        <div className="h-[360px] w-full mt-[90px] flex flex-wrap justify-center lg:justify-start">
          <div className="w-full lg:w-1/2 p-5 lg:pl-[50px] flex flex-col items-center lg:items-start ">
            <div className="flex items-center mb-5 lg:mb-20">
              <img src={address} alt="Address Icon" className="h-[70px] mt-8" />
              <div className="ml-8">
                <h1 className="text-black text-[20px] lg:text-[30px] font-bold">Address</h1>
                <p className="text-gray-600 text-[12px] lg:text-[15px] font-semibold mt-2 ">
                  R/o 2/1, 3rd Main Road, Bharathi Nagar, Dharapadavedu, Katpadi, Vellore-632007
                </p>
              </div>
            </div>
            <div className="flex items-center mb-5 lg:mb-20 md:mr-0 mr-36 ">
              <img src={phone} alt="Phone Icon" className="h-[70px] mt-8" />
              <div className="ml-8">
                <h1 className="text-black text-[20px] lg:text-[30px] font-bold">Phone</h1>
                <p className="text-gray-600 text-[12px] lg:text-[15px] font-semibold mt-2 ">
                  +91 8148783128
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 p-5  lg:pl-[50px] flex flex-col items-center lg:items-start md:mr-0 mr-36">
            <div className="flex items-center mb-5 lg:mb-20">
              <img src={gmail} alt="Email Icon" className="h-[70px] mt-8" />
              <div className="ml-8">
                <h1 className="text-black text-[20px] lg:text-[30px] font-bold">Email</h1>
                <p className="text-gray-600 text-[12px] lg:text-[15px] font-semibold mt-2">
                  entreovert@gmail.com
                </p>
              </div>
            </div>
            <div className="flex items-center mb-5 lg:mb-20">
              <img src={social} alt="Social Icon" className="h-[70px] mt-8" />
              <div className="ml-8">
                <h1 className="text-black text-[20px] lg:text-[30px] font-bold">Social</h1>
                <div className="flex space-x-4 mt-2">
                  <a href="https://www.linkedin.com/company/entreovert-people-association/">
                    <img src={lin} alt="LinkedIn Icon" className="h-[30px]" />
                  </a>
                  <a href="https://www.instagram.com/entreovert?igsh=MXhxemdrd2RkZ2pjcg==">
                    <img src={insta} alt="Instagram Icon" className="h-[30px]" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
