import React from "react";
import Navbar from "../../body/Navbar";
import Footer from "../../body/Footer";
import img1 from "../img/j1.png";
import img2 from "../img/j2.png";
import img3 from "../img/j3.png";

const Jeevan = () => {
  const Points = (item) => {
    return (
      <div key={item.id}>
        <div className="">
          <div>
            <h1 className=" text-blacke pl-14 py-5 text-[45px] font-black">
              Jeevan
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify mt-[25px] ">
              We are thrilled to present our groundbreaking HealthTech startup
              known as Jeevan before you all that aims to revolutionize the
              healthcare landscape. Our mission is simple yet transformative- to
              harness the power of technology to enhance patient outcomes,
              streamline healthcare processes, and ultimately improve the
              quality of life for individuals worldwide by creating an
              integrated app that aims to provide the solution to every problem
              in one platform.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Team Details
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              We are currently working in a team of four founders, all of whom
              are third-years studying Computer Science at the Vellore Institute
              of Technology, Chennai. All of us are innovative and hard-working,
              unique in our own ways with an unbreakable synergy bonding us
              together.
            </p>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Harsh Pandey is the CEO of our organization whose job is
              to plan ahead and strategize.
            </h1>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Atharv Pathak is the COO who manages and implements the
              company’s plans.
            </h1>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Suyash Agrawal is the CFO who is currently managing
              finances.
            </h1>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Vardan Tuteja is the CGO whose job is to focus on growth.
            </h1>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Location
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Our startup is currently headquartered in our institute, and we
              are operating from the city of Chennai. However, it is our hope
              that we soon expand nationally as well as globally.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Identified Problem
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              The healthcare industry is at a crossroads, facing challenges that
              have only been exacerbated by recent global events. Overburdened
              healthcare systems, inefficiencies in patient care, and the need
              for remote and personalized medical solutions have become more
              evident than ever. Healthcare services are fragmented,
              overburdened, and outdated. Patients are demanding more
              accessible, convenient, and tailored healthcare experiences, while
              providers struggle to deliver quality care efficiently.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Innovative Solution
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Enter Jeevan, your partner in advancing healthcare through
              cutting-edge technology. Our suite of innovative solutions
              addresses these challenges head-on, empowering both patients and
              healthcare providers with the tools they need for a more
              connected, efficient, and effective healthcare ecosystem in a
              single integrated platform.
            </p>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Our goal is to revolutionize healthcare by seamlessly integrating
              cutting-edge AI and Machine Learning technologies with traditional
              healthcare services. We aim to provide comprehensive and
              accessible healthcare solutions under one roof, ensuring a
              healthier world for all
            </p>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              This platform offers a comprehensive telemedicine solution that
              combines high-quality video consultations alongside AI-powered
              diagnostics, enabling patients to receive accurate and timely
              medical advice from the comfort of their homes. We also leverage
              machine learning algorithms and patient data to provide
              personalized treatment recommendations, enhancing patient
              engagement and adherence to medical advice.
            </p>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              A comprehensive health ecosystem is being created by Jeevan
              through an integrated platform that brings together a wide range
              of health services under one umbrella and enables unified access
              by allowing users to access telemedicine, appointment booking,
              health monitoring, and more through a single, user-friendly
              interface.
            </p>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Jeevan streamlines operations, reducing overhead costs and making
              quality healthcare services more affordable. Making it
              cost-friendly AI-driven insights empower users with personalized
              health recommendations and treatment plans. Jeevan facilitates
              seamless communication between users and healthcare providers,
              fostering improved patient-doctor relationships.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Milestones and Traction Achieved
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Our revenue streams are diversified, including subscription-based
              models for healthcare providers, direct-to-consumer services, and
              partnerships with insurance providers looking to enhance patient
              care while reducing costs related to avoidable hospitalizations.
              Currently, the services we offer include
            </p>
            <h1 className=" text-black text-[22px] font-semibold px-14 text-justify ">
              <br />⦿ Jeevan Bot
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Our AI chatbot named 'Jeevan' is designed to provide efficient and
              personalized health advice to each of our consumers. This bot can
              create diet plans, nutritional advice, health exercises, and even
              selective advice in response to asked questions.
            </p>
            <h1 className=" text-black text-[22px] font-semibold px-14 text-justify ">
              <br />⦿ 10-minute medicine delivery
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              combining our marketplace with a unique distribution approach,
              Jeevan plans to create a system where we can deliver any ordered
              medicines in 10 minutes to all Tier-1 and Tier-2 cities of our
              nation. We plan to create logistical centers that would allow us
              to create an efficient delivery system.
            </p>
            <h1 className=" text-black text-[22px] font-semibold px-14 text-justify ">
              <br />⦿ Disease diagnosis AI tool
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Jeevan has developed an AI tool through which customers can
              diagnose diseases just by listing known symptoms. This does not
              seek to replace but rather complement them, and allow patients to
              get further explanation.
            </p>
            <h1 className="text-black text-[22px] font-semibold px-14 text-justify ">
              <br />⦿ Free Telemedicine Service
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Jeevan also plans to provide free telemedicine services to every
              single person who needs to consult a doctor. This would not only
              allow us to attract customers, but provide world-class healthcare
              to each and every one of our customers.
            </p>
            <h1 className=" text-black text-[22px] font-semibold px-14 text-justify ">
              <br />⦿ Generic Medicine
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              We plan to tie up with manufacturers of generic medicines, to
              introduce generic medicines to consumers without any middleman in
              between. This would reduce the cost of generic medicines by more
              than 80% and give a cheap alternative to consumers.
            </p>
            <p className="text-gray-600 font-semibold px-14 text-justify mt-[60px] ">
              Jeevan is constantly improving and developing, it was an idea
              formed just two months ago but even then, we have generated a
              revenue of around 20,000 rupees and more than four hundred people
              have used our chatbot. According to our survey, the digital
              healthcare sector would grow exponentially in the coming years
              with the government directly supporting the sector and six out of
              every seven youngsters are prepared to adopt AI solutions for
              healthcare. It is a growing multi-billion dollar market and now is
              the right time to enter.
            </p>
            <p className="text-gray-600 font-semibold px-14 text-justify mt-[35px] ">
              In closing, Jeevan is poised to transform healthcare as we know
              it. By seamlessly blending technology with compassionate care, we
              are dedicated to improving patient outcomes, reducing healthcare
              costs, and bringing healthcare into the 21st century. We invite
              you to join us on this incredible journey toward a healthier
              future for all.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Product Showcases
            </h1>
            <div className="mx-[150px] flex gap-20 mt-20">
              <img
                src={img1}
                alt=""
                className="h-[550px] border-[6px] border-slate-500 p-1 rounded-2xl"
              />
              <img
                src={img2}
                alt=""
                className="h-[550px] border-[6px] border-slate-500 p-1 rounded-2xl"
              />
              <img
                src={img3}
                alt=""
                className="h-[550px] border-[6px] border-slate-500 p-1 rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[3350px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[180px]">
        <div>
          <Points />
        </div>
      </div>
      {/* <div className="relative top-[145px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Jeevan;
