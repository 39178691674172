import React from "react";
import Navbar from "../../body/Navbar";
import Footer from "../../body/Footer";
import img1 from "../img/DtL.png";

const DotstoLetters = () => {
  const Points = (item) => {
    return (
      <div key={item.id}>
        <div className="flex">
          <div>
            <h1 className=" text-black pl-14 py-5 text-[45px] font-black">
              Dots-to-Letters
            </h1>
            <p className="text-gray-600 font-semibold pl-14 text-justify mt-[25px] w-[600px]">
              The core team here at Dots-to-Letters consists of{" "}
              <spam className="font-bold text-red-500 text-[17px]">
                4 key developers
              </spam>
              . We are{" "}
              <spam className="font-bold text-red-500 text-[17px]">
                Sanjana Babu
              </spam>{" "}
              - Enthusiastic, Optimistic person and an Excellent programmer,{" "}
              <spam className="font-bold text-red-500 text-[17px]">
                Rohith N
              </spam>{" "}
              - Problem Solver, Energetic, Determined and Creative person,{" "}
              <spam className="font-bold text-red-500 text-[17px]">
                Yuvarani K
              </spam>
              - Supportive, Passionate coder who’s an amazing team player and{" "}
              <spam className="font-bold text-red-500 text-[17px]">
                Chiranjeevi M
              </spam>{" "}
              - Helpful and Resourceful person who’s a storehouse of
              information. Currently we plan to operate from Chennai. From this
              vibrant city, we are passionate to revolutionize accessibility for
              visually impaired individuals worldwide.
            </p>
            <p className="text-gray-600 font-semibold pl-14 text-justify mt-[25px] w-[600px]">
              The startup 'Dots-to-Letters' tackles the challenge of limited
              accessibility for the visually impaired, a community of around 285
              million worldwide. Reading braille is demanding and
              time-consuming, and braille materials are often bulky and hard to
              access. This poses barriers to education and employment. We use
              machine learning to convert braille into English text, displayed
              on a screen. This technology makes reading faster and more
              convenient with potential integration into smartphone apps. The
              additional text to speech and voice assistance would enhance the
              user experience. In today's inclusive landscape, this solution
              aligns with the push for accessibility and inclusivity, offering
              visually impaired individuals a more efficient and effective way
              to access information and opportunities. This can also be used by
              the people who aid the visually impaired, eradicating their need
              to learn braille to help make lives of the visually impaired
              easier.
            </p>
            <p className="text-gray-600 font-semibold pl-14 text-justify mt-[25px] w-[600px]">
              There are currently several apps on the market that offer Braille
              translation services, such as Be My Eyes, Voice Dream Reader, and
              Braille Translator. However, none of these apps offer real-time
              Braille-to-English character conversion using machine learning.
              This creates a unique selling proposition for our app.
              Dots-to-letters’ combination of real-time translation, high
              accuracy, and user-friendly interface and ability to generalize it
              across various languages sets it apart. Additionally, our
              user-driven feedback loop ensures continuous improvement, adapting
              to individual user preferences and expanding its capabilities over
              time.
            </p>
            <p className="text-gray-600 font-semibold pl-14 text-justify mt-[25px] w-[600px]">
              We are proud to share that Dots-to-letters has reached the
              advanced prototype stage, with successful internal tests
              demonstrating validation accuracy rates of over 99%. Here’s our
              team!
            </p>
          </div>
          <div>
            <img
              src={img1}
              alt=""
              className="h-[850px] w-[580px] ml-14 mt-24"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[1310px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[180px]">
        <div>
          <Points />
        </div>
      </div>
      {/* <div className="relative top-[96px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default DotstoLetters;
