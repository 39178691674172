import React from "react";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";
import { Link } from "react-router-dom";
import img1 from "./img/Our Portfolio.jpg";
import img2 from "./img/Ssnp.jpg";
import img3 from "./img/Mentors.jpg";

const Portfolio = () => {
  return (
    <div>
      <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen w-full font-poppins ">
        {/* <div className="">
          <Navbar />
        </div> */}
        <div className="pt-24 sm:pt-32 lg:pt-[180px]">
          <h1 className="border-[3px] border-gray-200 rounded-[20px] bg-white text-black h-fit w-[90%] max-w-[600px] mx-auto px-3 py-5 text-center text-[25px] sm:text-[35px] md:text-[45px] font-black">
            Portfolio
          </h1>
          <div className="mt-12 sm:mt-16 lg:mt-24 mx-4 sm:mx-8 md:mx-[155px]  flex flex-col md:flex-row gap-8 md:gap-[215px] items-center justify-center">
            <Link to="/Partners">
              <div className="bg-white hover:bg-gray-800 mb-5 hover:text-white h-[300px] sm:h-[350px] md:h-[380px] w-[300px] sm:w-[350px] md:w-[380px] rounded-[20px] shadow-lg transition-all duration-300">
                <img
                  src={img2}
                  alt=""
                  className="rounded-t-[20px] h-60 sm:h-64 md:h-72 w-full"
                />
                <h1 className="h-fit text-center py-2 sm:py-3 md:py-5 text-[20px] sm:text-[25px] md:text-[30px] font-bold">
                  Partners
                </h1>
              </div>
            </Link>
            <Link to="/Mentors">
              <div className="bg-white hover:bg-gray-800 mb-5 hover:text-white h-[300px] sm:h-[350px] md:h-[380px] w-[300px] sm:w-[350px] md:w-[380px] rounded-[20px] shadow-lg transition-all duration-300">
                <img
                  src={img3}
                  alt=""
                  className="rounded-t-[20px] h-60 sm:h-64 md:h-72 w-full"
                />
                <h1 className="h-fit text-center py-2 sm:py-3 md:py-5 text-[20px] sm:text-[25px] md:text-[30px] font-bold">
                  Mentors
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="relative top-[0px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Portfolio;
