import React from 'react'
import Pdfviewer1 from '../NewsLetter/PdfViewer2';

const Secondediton = () => {
  return (
    <div className=" min-h-screen flex items-center justify-center p-44 bg-gray-200">
    <Pdfviewer1 fileUrl= './Editon1.pdf' />
</div>
  )
}

export default Secondediton