import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import logo from "../img/Entro_img.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [Entrodropdown, setEntroNetworkdropdown] = useState(false);
  const [EntroMediadropdown, setEntroMediadropdown] = useState(false);
  const [Portfoliodropdown, setPortfoliodropdown] = useState(false);
  const [Contactdropdown, setContactdropdown] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="bg-white shadow-md fixed w-full z-40">
      <nav className="container mx-auto p-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-56 h-auto" />
        </div>

        {/* Mobile Menu Icon */}
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-800 hover:text-gray-600 focus:outline-none flex items-center justify-end transition"
          >
            {menuOpen ? (
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="text-2xl" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div className={`${menuOpen ? 'block' : 'hidden'} lg:hidden absolute inset-x-0 top-24 bg-white shadow-md border-t border-gray-200 py-4`}>
          <ul className="flex flex-col items-center text-gray-800 space-y-4 font-poppins font-bold text-md">
            <li>
              <Link to="/" className="hover:text-gray-600 transition">
                Home
              </Link>
            </li>
            <li>
              <Link to="/About" className="hover:text-gray-600 transition">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/Tries" className="hover:text-gray-600 transition">
                Initiatives
              </Link>
            </li>
            <li>
              <button
                onClick={() => setEntroNetworkdropdown(!Entrodropdown)}
                className="hover:text-gray-600 focus:outline-none flex items-center transition"
              >
                Entrovert Network
                <FontAwesomeIcon icon={Entrodropdown ? faChevronUp : faChevronDown} className="ml-1" />
              </button>
              {Entrodropdown && (
                <ul className="mt-1 bg-white border border-gray-200 shadow-lg rounded-md">
                  <li>
                    <Link to="/Chapters" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Chapters</Link>
                  </li>
                  <li>
                    <Link to="/Partners" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Partners</Link>
                  </li>
                  <li>
                    <Link to="/Mentors" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Mentors</Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={() => setEntroMediadropdown(!EntroMediadropdown)}
                className="hover:text-gray-600 focus:outline-none flex items-center transition"
              >
                Entrovert Media
                <FontAwesomeIcon icon={EntroMediadropdown ? faChevronUp : faChevronDown} className="ml-1" />
              </button>
              {EntroMediadropdown && (
                <ul className="mt-1 bg-white border border-gray-200 shadow-lg rounded-md">
                  <li>
                    <Link to="/Startup" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Startup Stories</Link>
                  </li>
                  <li>
                    <Link to="/Advertise" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Advertise With Us</Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={() => setPortfoliodropdown(!Portfoliodropdown)}
                className="hover:text-gray-600 focus:outline-none flex items-center transition"
              >
                Portfolio
                <FontAwesomeIcon icon={Portfoliodropdown ? faChevronUp : faChevronDown} className="ml-1" />
              </button>
              {Portfoliodropdown && (
                <ul className="mt-1 bg-white border border-gray-200 shadow-lg rounded-md">
                  <li>
                  <Link to="/Edition1" className="block px-6 py-2 text-gray-800 hover:bg-gray-100 transition">NewsLetter-First Edition</Link>
                </li>
                <li>
                  <Link to="/Edition2" className="block px-6 py-2 text-gray-800 hover:bg-gray-100 transition">NewsLetter-Second Edition</Link>
                </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={() => setContactdropdown(!Contactdropdown)}
                className="hover:text-gray-600 focus:outline-none flex items-center transition"
              >
                Contact
                <FontAwesomeIcon icon={Contactdropdown ? faChevronUp : faChevronDown} className="ml-1" />
              </button>
              {Contactdropdown && (
                <ul className="mt-1 bg-white border border-gray-200 shadow-lg rounded-md">
                  <li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcge65lo037Pem_I9ewvY0BY_vZCLlruAhFtlGFtYlEhfvfQ/viewform" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Become Mentors</a>
                  </li>
                  <li>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf77Dy8sl5nVbMT_k5q4HVlHKMP3fw7y6FHnUbHaO01o0G8cw/viewform?pli=1" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Partner with Us</a>
                  </li>
                  <li>
                    <Link to="/Contact" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Contact Us</Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex items-center text-md">
        <ul className="flex space-x-6 font-poppins mr-10 font-bold text-md">
          <li>
            <Link to="/" className="text-gray-800 hover:text-gray-600 transition">
              Home
            </Link>
          </li>
          <li>
            <Link to="/About" className="text-gray-800 hover:text-gray-600 transition">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/Tries" className="text-gray-800 hover:text-gray-600 transition">
              Initiatives
            </Link>
          </li>
          <li
            className="relative"
            onMouseEnter={() => setEntroNetworkdropdown(true)}
            onMouseLeave={() => setEntroNetworkdropdown(false)}
          >
            <button className="text-gray-800 hover:text-gray-600 focus:outline-none flex items-center transition">
              Entreovert Network
              <FontAwesomeIcon icon={Entrodropdown ? faChevronUp : faChevronDown} className="ml-1" />
            </button>
            {Entrodropdown && (
              <ul className="absolute left-0 mt-0 w-44 bg-white border border-gray-200 shadow-lg rounded-md">
                <li>
                  <Link to="/Chapters" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Chapter</Link>
                </li>
                <li>
                  <Link to="/Partners" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Partners</Link>
                </li>
                <li>
                  <Link to="/Mentors" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Mentors</Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className="relative"
            onMouseEnter={() => setEntroMediadropdown(true)}
            onMouseLeave={() => setEntroMediadropdown(false)}
          >
            <Link to="/Media">
              <button className="text-gray-800 hover:text-gray-600 focus:outline-none flex items-center transition">
                Entreovert Media
                <FontAwesomeIcon icon={EntroMediadropdown ? faChevronUp : faChevronDown} className="ml-1" />
              </button>
            </Link>
            {EntroMediadropdown && (
              <ul className="absolute left-0 mt-0 w-44 bg-white border border-gray-200 shadow-lg rounded-md">
                <li>
                  <Link to="/Startup" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">StartUP Stories</Link>
                </li>
                <li>
                  <Link to="/Contact" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Advertise With Us</Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className="relative"
            onMouseEnter={() => setPortfoliodropdown(true)}
            onMouseLeave={() => setPortfoliodropdown(false)}
          >
            <Link to="/Portfolio">
              <button className="text-gray-800 hover:text-gray-600 focus:outline-none flex items-center transition">
                Portfolio
                <FontAwesomeIcon icon={Portfoliodropdown ? faChevronUp : faChevronDown} className="ml-1" />
              </button>
            </Link>
            {Portfoliodropdown && (
              <ul className="absolute left-0 mt-0 w-44 bg-white border border-gray-200 shadow-lg rounded-md">
                <li>
                  <Link to="/Edition1" className="block px-6 py-2 text-gray-800 hover:bg-gray-100 transition">NewsLetter-First Edition</Link>
                </li>
                <li>
                  <Link to="/Edition2" className="block px-6 py-2 text-gray-800 hover:bg-gray-100 transition">NewsLetter-Second Edition</Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className="relative"
            onMouseEnter={() => setContactdropdown(true)}
            onMouseLeave={() => setContactdropdown(false)}
          >
            <button className="text-gray-800 hover:text-gray-600 focus:outline-none flex items-center transition">
              Contact
              <FontAwesomeIcon icon={Contactdropdown ? faChevronUp : faChevronDown} className="ml-1" />
            </button>
            {Contactdropdown && (
              <ul className="absolute right-0 -left-4 mt-0 w-44  bg-white border border-gray-200 shadow-lg rounded-md">
                <li>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcge65lo037Pem_I9ewvY0BY_vZCLlruAhFtlGFtYlEhfvfQ/viewform" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Become Mentors</a>
                </li>
                <li>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSf77Dy8sl5nVbMT_k5q4HVlHKMP3fw7y6FHnUbHaO01o0G8cw/viewform?pli=1" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Partner with us</a>
                </li>
                <li>
                  <Link to="/Contact" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition">Contact Us</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
