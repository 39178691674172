import img1 from "./img/DtL.png";
import img2 from "./img/healthai.png";
import img3 from "./img/aitecho.jpg";
import img4 from "./img/house.jpg";

const View = [
  {
    id: 1,
    key: "1",
    img: img1,
    head: "Dots-to-Letters",
    text: "We are Sanjana Babu - Enthusiastic, Optimistic person and an Excellent programmer, Rohith N - Problem Solver, Energetic, Determined and Creative person, Yuvarani K- Supportive, Passionate coder who’s an amazing team player and Chiranjeevi M - Helpful and Resourceful person who’s a storehouse of information.",
    content:
      "We are thrilled to present our groundbreaking HealthTech startup known as Jeevan before you all that aims to revolutionize the healthcare landscape. Our mission is simple yet transformative- to harness the power of technology to enhance patient outcomes, streamline healthcare processes, and ultimately improve the quality of life for individuals worldwide by creating an integrated app that aims to provide the solution to every problem in one platform.",
    link: "/DotstoLetters",
    phara1:
      "The core team here at Dots-to-Letters consists of 4 key developers. We are Sanjana Babu - Enthusiastic, Optimistic person and an Excellent programmer, Rohith N - Problem Solver, Energetic, Determined and Creative person, Yuvarani K- Supportive, Passionate coder who’s an amazing team player and Chiranjeevi M -  Helpful and Resourceful person who’s a storehouse of information. Currently we plan to operate from Chennai. From this vibrant city, we are passionate to revolutionize accessibility for visually impaired individuals worldwide.",
    phara2:
      "The startup 'Dots-to-Letters' tackles the challenge of limited accessibility for the visually impaired, a community of around 285 million worldwide. Reading braille is demanding and time-consuming, and braille materials are often bulky and hard to access. This poses barriers to education and employment. We use machine learning to convert braille into English text, displayed on a screen. This technology makes reading faster and more convenient with potential integration into smartphone apps. The additional text  to speech and voice assistance would enhance the user experience. In today's inclusive landscape, this solution aligns with the push for accessibility and inclusivity, offering visually impaired individuals a more efficient and effective way to access information and opportunities. This can also be used by the people who aid the visually impaired, eradicating their need to learn braille to help make lives of the visually impaired easier.",
    phara3:
      "There are currently several apps on the market that offer Braille translation services, such as Be My Eyes, Voice Dream Reader, and Braille Translator. However, none of these apps offer real-time Braille-to-English character conversion using machine learning. This creates a unique selling proposition for our app. Dots-to-letters’ combination of real-time translation, high accuracy, and user-friendly interface and ability to generalize it across various languages sets it apart. Additionally, our user-driven feedback loop ensures continuous improvement, adapting to individual user preferences and expanding its capabilities over time.",
    phara4:
      "We are proud to share that Dots-to-letters has reached the advanced prototype stage, with successful internal tests demonstrating validation accuracy rates of over 99%.  Here’s our team!",
  },
  {
    id: 2,
    key: "2",
    img: img2,
    head: "Jeevan",
    text: "We are currently working in a team of four founders, all of whom are third-years studying Computer Science at the Vellore Institute of Technology, Chennai. All of us are innovative and hard-working, unique in our own ways with an unbreakable synergy bonding us together.",
    content:
      "The core team here at Dots-to-Letters consists of 4 key developers. We are Sanjana Babu - Enthusiastic, Optimistic person and an Excellent programmer, Rohith N - Problem Solver, Energetic, Determined and Creative person, Yuvarani K- Supportive, Passionate coder who’s an amazing team player and Chiranjeevi M - Helpful and Resourceful person who’s a storehouse of information. Currently we plan to operate from Chennai. From this vibrant city, we are passionate to revolutionize accessibility for visually impaired individuals worldwide.",
    link: "/Jeevan",
  },
  {
    id: 3,
    key: "3",
    img: img3,
    head: "Matrix",
    text: "Our AI as a Service (AIaaS) startup offers an accessible solution to businesses looking to embrace AI technology. Our idea is to provide an easy-to-use platform, pre-built AI models, and expert guidance, allowing businesses to integrate AI without extensive resources or technical skills.",
    content:
      "Our AI as a Service (AIaaS) startup offers an accessible solution to businesses looking to embrace AI technology. Our idea is to provide an easy-to-use platform, pre-built AI models, and expert guidance, allowing businesses to integrate AI without extensive resources or technical skills. Our customizable tools cater to various industries, streamlining processes, extracting insights, and improving customer experiences. We prioritize responsible AI practices for unbiased results. Through our service, businesses can enhance efficiency, innovation, and competitiveness in an AI-driven landscape.",
    link: "/Matrix",
  },
  {
    id: 4,
    key: "4",
    img: img4,
    head: "Eternals",
    text: "Our AI as a Service (AIaaS) startup offers an accessible solution to businesses looking to embrace AI technology. Our idea is to provide an easy-to-use platform, pre-built AI models, and expert guidance, allowing businesses to integrate AI without extensive resources or technical skills.",
    content:
      "We offer innovative housing solutions that are both cost-efficient and highly beneficial for our clients. Our approach prioritizes affordability without compromising on quality or comfort. By leveraging modern techniques and materials, we ensure that our homes meet the highest standards. Our commitment to client satisfaction drives us to tailor each project to individual needs. Discover a smarter way to achieve your housing dreams with us.",
    link: "/Eternals",
  },
];

export default View;
