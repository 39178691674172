// src/App.js
import React from 'react';
import Pdfviewer1 from '../NewsLetter/Pdfviewer1';
// import edtion1 from 'src\portfolio\Editon1.pdf'
 

const Firstedition = () => {
    return (
        <div className=" min-h-screen flex items-center justify-center p-44 bg-gray-200">
            <Pdfviewer1 fileUrl= './Edtion2.pdf' />
        </div>
    );
};

export default Firstedition;
