import React from "react";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";
import img1 from "./eChapters/vellore/v1V.png";
import img2 from "./eChapters/vellore/v2V.png";
import img3 from "./eChapters/vellore/v3V.png";
import img4 from "./eChapters/vellore/v4G.png";
import img5 from "./eChapters/vellore/v5A.png";

const Vellore = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[2403px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[120px]"></div>
      <div className="flex">
        <div className="bg-white h-16 w-2 rounded-2xl mt-[80px] ml-[463px]"></div>
        <div className="bg-white h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
        <div className="bg-white h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
        <div>
          <h1 className="text-[#770AAA] bg-white h-fit w-fit px-5 py-6 rounded-xl font-extrabold text-[40px] uppercase mt-14 ml-[8px]">
            Vellore
          </h1>
        </div>
        <div className="bg-white h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
        <div className="bg-white h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
        <div className="bg-white h-16 w-2 rounded-2xl mt-[80px] ml-[8px]"></div>
      </div>
      <div>
        {/* <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[440px] mt-36">
        Vellore Institute of Technology
        </h2> */}
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img1} alt="" className="h-[600px]" />
          <img src={img2} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img3} alt="" className="h-[600px]" />
          <img src={img4} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-[405px] mt-20">
          <img src={img5} alt="" className="h-[600px]" />
        </div>
      </div>
      {/* <div>
        <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[318px] mt-36">
        C. Abdul Hakeem College of Engineering & Technology
        </h2>
        <div className="flex ml-[405px] mt-20">
          <img src={img4} alt="" className="h-[600px]" />
        </div>
        </div>
        <div>
        <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[370px] mt-36">
        Ganadipathy Tulsi's Jain Engineering College
        </h2>
        <div className="flex ml-[405px] mt-20">
          <img src={img5} alt="" className="h-[600px]" />
        </div>
        </div>
        <div>
        <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[515px] mt-36">
        Auxilium College
        </h2>
        <div className="flex ml-[405px] mt-20">
          <img src={img6} alt="" className="h-[600px]" />
        </div>
        </div> */}
      {/* <div className="relative top-[79px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Vellore;
