import React from "react";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";
import img1 from "./eChapters/coimbatore/cb1K.png";
import img2 from "./eChapters/coimbatore/cb2K.png";

const Coimbatore = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[1250px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[120px]"></div>
      <div className="flex">
        <div className="bg-white h-16 w-2 rounded-2xl mt-[80px] ml-[420px]"></div>
        <div className="bg-white h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
        <div className="bg-white h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
        <div>
          <h1 className="text-[#770AAA] bg-white h-fit w-fit px-5 py-6 rounded-xl font-extrabold text-[40px] uppercase mt-14 ml-[8px]">
            Coimbatore
          </h1>
        </div>
        <div className="bg-white h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
        <div className="bg-white h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
        <div className="bg-white h-16 w-2 rounded-2xl mt-[80px] ml-[8px]"></div>
      </div>
      <div>
        {/* <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[400px] mt-36">
          Karpagam College of Higher Education
        </h2> */}
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img1} alt="" className="h-[600px]" />
          <img src={img2} alt="" className="h-[600px]" />
        </div>
      </div>
      {/* <div className="relative top-[79px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Coimbatore;
