import React from "react";
import ps1 from "./img/Patners/ps1.png";
import ps2 from "./img/Patners/ps2.png";
import ps3 from "./img/Patners/ps3.png";
import ps4 from "./img/Patners/ps4.png";
import ps5 from "./img/Patners/ps5.png";
import ps6 from "./img/Patners/ps6.png";
import ps7 from "./img/Patners/ps7.png";
import ps8 from "./img/Patners/ps8.png";
import ps9 from "./img/Patners/ps9.png";
import ps10 from "./img/Patners/ps10.png";
import ps11 from "./img/Patners/ps11.png";
import or1 from "./img/Patners/or1.png";
import or2 from "./img/Patners/or2.png";
import or3 from "./img/Patners/or3.png";
import or4 from "./img/Patners/or4.png";
import or5 from "./img/Patners/or5.png";
import or6 from "./img/Patners/or6.png";
import or7 from "./img/Patners/or7.png";

const Partners = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen w-full font-poppins">
      <div className="pt-24">
        {/* Partners section */}
        <h1 className="border-4 bg-white rounded-3xl text-black h-fit w-3/4 md:w-2/4 mx-auto px-3 mt-12 py-5 text-center text-5xl font-black">
          Partners
        </h1>

        <div className="mt-20 mx-5 md:mx-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Partner 1 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps1} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Beej Network
              </h1>
            </div>

            {/* Partner 2 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps2} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Smartfilings
              </h1>
            </div>

            {/* Partner 3 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps3} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                eAuditor Office
              </h1>
            </div>

            {/* Partner 4 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps4} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Ennoble IP
              </h1>
            </div>

            {/* Partner 5 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps5} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                KnackBay Technologies Pvt Ltd
              </h1>
            </div>

            {/* Partner 6 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps6} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                WABI SABI COUNCIL
              </h1>
            </div>

            {/* Partner 7 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps7} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Thinkin Lab
              </h1>
            </div>

            {/* Partner 8 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps8} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Tutelr
              </h1>
            </div>

            {/* Partner 9 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps9} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                AY Ventures
              </h1>
            </div>

            {/* Partner 10 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps10} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Startup Tales
              </h1>
            </div>

            {/* Partner 11 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={ps11} alt="Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Finance in Minutes
              </h1>
            </div>
          </div>
        </div>

        {/* Outreach Partners section */}
        <h1 className="border-4 bg-white rounded-3xl text-black h-fit w-3/4 md:w-2/4 mx-auto mt-20 px-3 py-5 text-center text-5xl font-black">
          Outreach Partners
        </h1>

        <div className="mt-20 mx-5 md:mx-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Outreach Partner 1 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={or1} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                BHARATH Technology Business Incubator
              </h1>
            </div>

            {/* Outreach Partner 2 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={or2} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                K. Ramakrishnan College of Technology
              </h1>
            </div>

            {/* Outreach Partner 3 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={or3} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Vel Tech TBI
              </h1>
            </div>

            {/* Outreach Partner 4 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={or4} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Cauvery College for Women
              </h1>
            </div>

            {/* Outreach Partner 5 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={or5} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                Annai Meera Engineering College
              </h1>
            </div>

            {/* Outreach Partner 6 */}
            <div className="bg-white rounded-xl shadow-md p-5">
              <img src={or6} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                VIT - Vellore Institute of Technology
              </h1>
            </div>

            {/* Outreach Partner 7 */}
            <div className="bg-white rounded-xl shadow-md mb-5 p-5">
              <img src={or7} alt="Outreach Partner Logo" className="h-24 mx-auto" />
              <h1 className="text-black text-center mt-6 text-2xl font-black">
                St. Peter's Engineering College
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
