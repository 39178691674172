import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Tab1 from "../home/Tab1";

const NavFoot = () => {
  return (
    <div>
      <div className="">
        <Navbar />
      </div>
      <div className=" ">
        <Outlet />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default NavFoot;
