import React from "react";
import Navbar from "../../body/Navbar";
import Footer from "../../body/Footer";

const Matrix = () => {
  const Points = (item) => {
    return (
      <div key={item.id}>
        <div className="">
          <div>
            <h1 className=" text-black pl-14 py-5 text-[45px] font-black">
              Matrix
            </h1>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Our team
            </h1>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Faisal Ahamad (student developer)
            </h1>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Imran Khan (student developer)
            </h1>
            <h1 className=" text-gray-600 font-semibold px-14 text-justify ">
              <br />⦿ Mohammed faizal (student developer)
            </h1>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Problems
            </h1>
            <p className="text-gray-200 font-semibold px-14 text-justify ">
              Even though there is a rising need for AI solutions across a range
              of industries, many firms still encounter substantial obstacles
              when trying to deploy and use the technology. Organisations are
              unable to fully utilise the potential of AI due to a lack of
              resources, a lack of internal expertise, and the complexity of
              building, implementing, and maintaining AI systems. As a result,
              there is an obvious need for an AI as a Service firm that provides
              a thorough and accessible platform, offering user-friendly tools,
              pre-built models, and professional assistance to businesses of all
              kinds.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Solution
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Our AI as a Service (AIaaS) startup offers an accessible solution
              to businesses looking to embrace AI technology. Our idea is to
              provide an easy-to-use platform, pre-built AI models, and expert
              guidance, allowing businesses to integrate AI without extensive
              resources or technical skills. Our customizable tools cater to
              various industries, streamlining processes, extracting insights,
              and improving customer experiences. We prioritize responsible AI
              practices for unbiased results. Through our service, businesses
              can enhance efficiency, innovation, and competitiveness in an
              AI-driven landscape.By providing on-demand access to cutting-edge
              AI capabilities, we enable businesses to unlock new levels of
              efficiency, innovation, and competitiveness in an increasingly
              AI-driven world.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Location
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Currently we are developing our startup (yet to be) at our college
              (k.Ramakrishnan college of technology) in richy.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Product
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              As of now we a created a Chat bot using an open source AI model.
              With necessary funding we could be able to create a more advanced
              AI modern to power or AI Tools
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[1375px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[180px]">
        <div>
          <Points />
        </div>
      </div>
      {/* <div className="relative top-[145px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Matrix;
