import React from "react";
import Navbar from "../body/Navbar";
import logo from "../img/Entreovert-logo.png";
import Footer from "../body/Footer";

const Tries = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen ">
      {/* Navbar component can be added here */}
      <div className="pt-16 px-4 sm:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-24">
            {/* Card 1 */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <img src={logo} alt="" className="h-20 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold text-center">Invest Circle</h2>
            </div>
            {/* Card 2 */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <img src={logo} alt="" className="h-20 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold text-center">Entreovert Boosters</h2>
            </div>
            {/* Card 3 */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <img src={logo} alt="" className="h-20 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold text-center">Entreovert Takeoff</h2>
            </div>
            {/* Card 4 */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <img src={logo} alt="" className="h-20 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold text-center">Entreovert Mentor</h2>
            </div>
            {/* Card 5 */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <img src={logo} alt="" className="h-20 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold text-center">Entreovert Startupcoach</h2>
            </div>
            {/* Card 6 */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <img src={logo} alt="" className="h-20 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold text-center">Entreovert Kickstart</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Footer component can be added here */}
    </div>
  );
};

export default Tries;
