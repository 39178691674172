import React from "react";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";
import img1 from "./eChapters/ranipet/r1A.png";
import img2 from "./eChapters/ranipet/r2A.png";
import img3 from "./eChapters/ranipet/r3A.png";
import img4 from "./eChapters/ranipet/r4A.png";
import img5 from "./eChapters/ranipet/r5A.png";
import img6 from "./eChapters/ranipet/r6A.png";
import img7 from "./eChapters/ranipet/r7A.png";
import img8 from "./eChapters/ranipet/r8A.png";
import img9 from "./eChapters/ranipet/r9A.png";
import img10 from "./eChapters/ranipet/r10C.png";
import img11 from "./eChapters/ranipet/r11S.png";

const Ranipet = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[4443px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[120px]"></div>
      <div className="flex">
        <div className="bg-white h-16 w-2 rounded-2xl mt-[80px] ml-[455px]"></div>
        <div className="bg-white h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
        <div className="bg-white h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
        <div>
          <h1 className="text-[#770AAA] bg-white h-fit w-fit px-5 py-6 rounded-xl font-extrabold text-[40px] uppercase mt-14 ml-[8px]">
            Ranipet
          </h1>
        </div>
        <div className="bg-white h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
        <div className="bg-white h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
        <div className="bg-white h-16 w-2 rounded-2xl mt-[80px] ml-[8px]"></div>
      </div>
      <div>
        {/* <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[335px] mt-36">
        Annai Mira College of Engineering and Technology
        </h2> */}
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img1} alt="" className="h-[600px]" />
          <img src={img2} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img3} alt="" className="h-[600px]" />
          <img src={img4} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img5} alt="" className="h-[600px]" />
          <img src={img6} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img7} alt="" className="h-[600px]" />
          <img src={img8} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-32 mt-20">
          <img src={img9} alt="" className="h-[600px]" />
          <img src={img10} alt="" className="h-[600px]" />
        </div>
        <div className="flex gap-40 ml-[405px] mt-20">
          <img src={img11} alt="" className="h-[600px]" />
        </div>
      </div>
      {/* <div>
        <h2 className="text-white border-[2px] border-slate-500 h-fit w-fit px-2 py-3 rounded-xl font-bold text-[23px] ml-[390px] mt-36">
        Arcot Sri Mahalakshmi Women's College
        </h2>
        <div className="flex ml-[405px] mt-20">
          <img src={img0} alt="" className="h-[600px]" />
        </div>
        </div> */}
      {/* <div className="relative top-[79px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Ranipet;
