import React from "react";
import Navbar from "../body/Navbar";
import { Link } from "react-router-dom";
import ariyalur from "../img/Ariyalur2.png";
import chennai from "../img/chennai2.png";
import coimbatore from "../img/Coimbatore2.png";
import nagapattinam from "../img/Nagapattinam2.png";
import ranipet from "../img/Ranipet2.png";
import tiruchirappalli from "../img/Tiruchirappalli2.png";
import vellore from "../img/vellore2.png";
import Footer from "../body/Footer";

const Chapters = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen w-full font-poppins">
      {/* Navbar */}
      {/* <Navbar /> */}
      
      <div className="pt-24"></div>

      {/* Hero section */}
      <div className="  ">
        <h1 className="text-black text-2xl md:text-5xl font-bold pt-20 md:pl-20 pl-2 flex justify-center items-center">
          ENTREOVERT
          <span className="font-semibold border-l-4  border-purple-600 ml-2 pl-5">
            Chapters
          </span>
        </h1>
        <p className="text-black md:text-xl text-xs font-semibold md:ml-20 ml-2 flex justify-center items-center mt-3">
          Entreovert is present in cities all over India
        </p>
      </div>

      {/* List of chapters */}
      <div className="mt-10 mx-5 lg:mx-20 xl:mx-40">
        <h1 className="text-gray-800 text-3xl font-semibold mt-10 flex justify-center items-center">
          List Of Chapters
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-5">
          {/* Ariyalur */}
          <Link to="">
            <div className="bg-white text-center rounded-lg shadow-md p-5">
              <img src={ariyalur} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Ariyalur</h3>
            </div>
          </Link>
          
          {/* Chennai */}
          <Link to="">
            <div className="bg-white text-center rounded-lg shadow-md p-5">
              <img src={chennai} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Chennai</h3>
            </div>
          </Link>
          
          {/* Coimbatore */}
          <Link to="/Coimbatore">
            <div className="bg-white text-center rounded-lg shadow-md p-5">
              <img src={coimbatore} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Coimbatore</h3>
            </div>
          </Link>
          
          {/* Nagapattinam */}
          <Link to="">
            <div className="bg-white text-center rounded-lg shadow-md p-5">
              <img src={nagapattinam} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Nagapattinam</h3>
            </div>
          </Link>
          
          {/* Ranipet */}
          <Link to="/Ranipet">
            <div className="bg-white text-center rounded-lg shadow-md p-5">
              <img src={ranipet} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Ranipet</h3>
            </div>
          </Link>
          
          {/* Tiruchirappalli */}
          <Link to="/Tiruchirappalli">
            <div className="bg-white text-center rounded-lg shadow-md p-5">
              <img src={tiruchirappalli} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Tiruchirappalli</h3>
            </div>
          </Link>
          
          {/* Vellore */}
          <Link to="/Vellore">
            <div className="bg-white text-center rounded-lg shadow-md mb-5 p-5">
              <img src={vellore} alt="" className="h-48 rounded-lg mx-auto" />
              <h3 className="font-bold text-xl mt-3">Vellore</h3>
            </div>
          </Link>
        </div>
      </div>

      {/* Footer */}
      {/* <Footer /> */}
      {/* <div className="absolute bottom-0 w-full">
        <Footer />
      </div> */}
    </div>
  );
};

export default Chapters;
