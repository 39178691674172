import React from "react";
import insta from "../img/instagram.png";
import lin from "../img/linkedin.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-gray-100 py-10 font-poppins">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-around items-center md:items-start">
          <Link to="/Portfolio">
            <div className="mb-6 md:mb-0">
              <h2 className="text-xl font-bold mb-4">Portfolio</h2>
              {/* <Link to="/portfolio" className="text-gray-700 hover:text-gray-900">View Projects</Link> */}
            </div>
          </Link>
          <Link to="/Media">
            <div className="mb-6 md:mb-0">
              <h2 className="text-xl font-bold mb-4">Entrevert Media</h2>
              {/* <Link to="/entrevertmedia" className="text-gray-700 hover:text-gray-900">About Us</Link> */}
            </div>
          </Link>
          <Link to="/Chapters">
            <div className="mb-6 md:mb-0">
              <h2 className="text-xl font-bold mb-4">Chapter</h2>
              {/* <Link to="/chapter" className="text-gray-700 hover:text-gray-900">Our Story</Link> */}
            </div>
          </Link>
          <Link to="/Contact">
            <div className="mb-6 md:mb-0">
              <h2 className="text-xl font-bold mb-4">Contact Us</h2>
              {/* <Link to="/contactus" className="text-gray-700 hover:text-gray-900">Get in Touch</Link> */}
            </div>
          </Link>
        </div>
        <div className="flex justify-center items-center mt-6">
          <div className="text-center md:text-left text-gray-600">
            © 2024 ALL COPYRIGHTS RESERVED TO ENTREOVERT PEOPLE ASSOCIATION.
          </div>
          <div className=" items-center ml-28">
            <span className="text-xl font-bold mr-4">Follow Us</span>
            <span className="flex">
            <a href="https://www.instagram.com/entreovert?igsh=MXhxemdrd2RkZ2pjcg==">
              <img src={insta} alt="Instagram" className="h-8 w-8" />
            </a>
            <a href="https://www.linkedin.com/company/entreovert-people-association/" className="mx-2">
              <img src={lin} alt="LinkedIn" className="h-8 w-8" />
            </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
