import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/Entreovert-logo.png";
import idea from "../img/idea.png";
import box from "../img/package.png";
import phone from "../img/phone.png";
import join from "../img/join.png";
import job from "../img/career.png";
import laptop from "../img/laptop.png";
import info from "../img/resume.png";
import Navbar from "../body/Navbar";
import Footer from "../body/Footer";

const Tab1 = () => {
  return (
      <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen flex justify-center items-center md:h-[800px] ">
        <div className="hidden md:flex relative w-full max-w-[1200px]  ">
        <Link to="/About" className="">
          <div className="b1 hover:-translate-x-5 bg-white h-[200px] w-[200px] absolute  -mt-96 md:-mt-a top-[190px] left-[120px] rounded-tl-[20px]">
            <div className="bc1 bg-white h-[50px] w-[100px]  absolute top-[50px] left-[160px] "></div>
            <div className="bc1 bg-white h-[42px] w-[100px]  absolute top-[158px] left-[160px]"></div>
            <div className="bc1 bg-white h-[100px] w-[50px] absolute top-[135px] left-[90px] "></div>
            <img
              src={logo}
              alt="Logo Entreovert"
              className="h-[180px] absolute"
            />
          </div>
        </Link>

        <Link to="/Tries">
          <div className="b1 hover:-translate-y-5 bg-white h-[200px] w-[200px] absolute -mt-96  top-[190.5px] left-[385px]   ">
            <div className="bc1 bg-white h-[50px] w-[150px]  absolute top-[103.3px] right-[110px] "></div>
            <div className="bc1 bg-white h-[45px] w-[80px] absolute bottom-[155px] right-[180px] "></div>
            <div className="bc1 bg-white h-[75px] w-[80px] absolute bottom-[0px] left-[179px] "></div>
            <div className="bc1 bg-white h-[55px] w-[80px] absolute bottom-[145px] left-[179px] "></div>
            <div className="bc1 bg-white h-[100px] w-[65px] absolute top-[135px] left-[25px] "></div>
            <div className="bc1 bg-white h-[100px] w-[80px] absolute top-[135px] left-[179px] "></div>
            <div className="absolute">
              <img src={idea} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">
                EPA Initiatives
              </h5>
            </div>
          </div>
        </Link>

        <Link to="/Chapters">
          <div className="b1 hover:-translate-y-5 bg-white h-[200px] w-[200px] absolute  -mt-96  top-[190.5px] left-[650px]   ">
            <div className="bc1 bg-white h-[60px] w-[80px] absolute bottom-[80px] right-[179px] "></div>
            <div className="bc1 bg-white h-[95px] w-[80px] absolute bottom-[105px] left-[179px] "></div>
            <div className="bc1 bg-white h-[100px] w-[65px] absolute top-[135px] left-[85px] "></div>
            <div className="absolute">
              <img src={box} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">
                EPA Chapters
              </h5>
            </div>
          </div>
        </Link>

        <Link to="/Contact">
          <div className="b1 hover:translate-x-5 bg-white h-[200px] w-[200px] absolute -mt-96  top-[190.5px] left-[915px] rounded-tr-[20px]   ">
            <div className="bc1 bg-white h-[100px] w-[80px] absolute bottom-[0px] right-[179px] "></div>
            <div className="bc1 bg-white h-[100px] w-[95px] absolute right-0 top-[135px] "></div>
            <div className="absolute">
              <img src={phone} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">Contact</h5>
            </div>
          </div>
        </Link>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcge65lo037Pem_I9ewvY0BY_vZCLlruAhFtlGFtYlEhfvfQ/viewform">
          {/* <Link to="/"> */}
          <div className="b1 hover:-translate-x-5 bg-white h-[200px] w-[200px] absolute -mt-96  top-[430px] left-[120px] rounded-bl-[20px]   ">
            <div className="bc1 bg-white h-[100px] w-[85px] absolute bottom-[135px] "></div>
            <div className="bc1 bg-white h-[100px] w-[55px] absolute bottom-[135px] left-[145px] "></div>
            <div className="bc1 bg-white h-[90px] w-[80px] absolute bottom-[75px] left-[179px] "></div>
            <div className="absolute">
              <img src={join} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">Join Now</h5>
            </div>
          </div>
          {/* </Link> */}
        </a>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf77Dy8sl5nVbMT_k5q4HVlHKMP3fw7y6FHnUbHaO01o0G8cw/viewform?pli=1">
          {/* <Link to=""> */}
          <div className="b1 hover:translate-y-5 bg-white h-[200px] w-[200px] absolute -mt-96  top-[430px] left-[385px] ">
            <div className="bc1 bg-white h-[65px] w-[80px] absolute bottom-[170px] right-[179px] "></div>
            <div className="bc1 bg-white h-[70px] w-[80px] absolute top-[130px] right-[179px] "></div>
            <div className="bc1 bg-white h-[100px] w-[80px] absolute bottom-[135px] left-[95px] "></div>
            <div className="bc1 bg-white h-[82px] w-[100px]  absolute left-[160px]"></div>
            <div className="bc1 bg-white h-[47px] w-[100px]  absolute top-[153px] left-[160px]"></div>
            <div className="absolute">
              <img src={job} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">EPA Career</h5>
            </div>
          </div>
          {/* </Link> */}
        </a>

        <Link to="/Media">
          <div className="b1 hover:translate-y-5 bg-white h-[200px] w-[200px] absolute -mt-96 top-[430px] left-[650px] ">
            <div className="bc1 bg-white h-[60px] w-[80px] absolute bottom-[53px] right-[180px] "></div>
            <div className="bc1 bg-white h-[100px] w-[80px] absolute bottom-[135px] "></div>
            <div className="bc1 bg-white h-[100px] w-[45px] absolute bottom-[135px] left-[155px] "></div>
            <div className="bc1 bg-white h-[82px] w-[100px]  absolute bottom-[153px] left-[160px]"></div>
            <div className="bc1 bg-white h-[59px] w-[100px]  absolute top-[141px] left-[160px]"></div>
            <div className="absolute">
              <img src={laptop} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">EPA Media</h5>
            </div>
          </div>
        </Link>

        <Link to="/Portfolio">
          <div className="b1 hover:translate-x-5 bg-white h-[200px] w-[200px] absolute -mt-96  top-[430px] left-[915px] rounded-br-[20px] ">
            <div className="bc1 bg-white h-[82px] w-[100px]  absolute top-[53px] right-[160px]"></div>
            <div className="bc1 bg-white h-[100px] w-[100px] absolute bottom-[135px] "></div>
            <div className="absolute">
              <img src={info} alt="" className="h-[85px] mt-[40px] ml-14" />
              <h5 className="font-extrabold text-[22px] ml-10 ">
                EPA Portfolio
              </h5>
            </div>
          </div>
        </Link>
      </div>
      <div className="md:hidden flex flex-col mt-24 items-center">
        <Link to="/About">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={logo} alt="Logo Entreovert" className="h-[120px] mx-auto mt-5" />
          </div>
        </Link>

        <Link to="/Tries">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={idea} alt="EPA Initiatives" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">EPA Initiatives</h5>
          </div>
        </Link>

        <Link to="/Chapters">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={box} alt="EPA Chapters" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">EPA Chapters</h5>
          </div>
        </Link>

        <Link to="/Contact">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={phone} alt="Contact" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">Contact</h5>
          </div>
        </Link>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcge65lo037Pem_I9ewvY0BY_vZCLlruAhFtlGFtYlEhfvfQ/viewform">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={join} alt="Join Now" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">Join Now</h5>
          </div>
        </a>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf77Dy8sl5nVbMT_k5q4HVlHKMP3fw7y6FHnUbHaO01o0G8cw/viewform?pli=1">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={job} alt="EPA Career" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">EPA Career</h5>
          </div>
        </a>

        <Link to="/Media">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={laptop} alt="EPA Media" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">EPA Media</h5>
          </div>
        </Link>

        <Link to="/Portfolio">
          <div className="b1 hover:scale-105 bg-white h-[150px] w-[250px] mb-6 rounded-lg">
            <img src={info} alt="EPA Portfolio" className="h-[85px] mx-auto mt-4" />
            <h5 className="font-extrabold text-[18px] text-center mt-2">EPA Portfolio</h5>
          </div>
        </Link>
      </div>
    
      {/* <Navbar />
      <div className="relative top-[800px] w-[100%]">
        <Footer />
      </div> */}
    </div>
  );
};

export default Tab1;
