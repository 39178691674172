import React from "react";
import m1 from "./img/mentors/m1.jpg";
import m2 from "./img/mentors/m2.jpg";
import m3 from "./img/mentors/m3.jpg";
import m4 from "./img/mentors/m4.jpg";
import m5 from "./img/mentors/m5.jpg";
import m6 from "./img/mentors/m6.jpg";
import m7 from "./img/mentors/m7.jpg";
import m8 from "./img/mentors/m8.jpg";
import m9 from "./img/mentors/m9.jpg";
import m10 from "./img/mentors/m10.jpg";
import m11 from "./img/mentors/m11.jpg";
import m12 from "./img/mentors/m12.jpg";
import m13 from "./img/mentors/m13.jpg";
import m14 from "./img/mentors/m14.jpg";
import m15 from "./img/mentors/m15.jpg";
import m16 from "./img/mentors/m16.jpg";
import lin from "../img/linkedin.png";

const Mentors = () => {
  const Store1 = [
    {
      img: m1,
      name: "Arasi Arul",
      work1: "Founder - 60 Plus India",
      work2: "",
      link: "https://www.linkedin.com/in/arasiarul/",
    },
    {
      img: m2,
      name: "Krishnamani Kannan",
      work1: "CEO 7 CO-Founder Deliver.sg",
      work2: "",
      link: "https://www.linkedin.com/in/krishnamani-kannan-deliversg/",
    },
    {
      img: m3,
      name: "Prakadesh Subramanian",
      work1: "CEO & Founder Zero2Bilion",
      work2: "Mission Towards SD",
      link: "https://www.linkedin.com/in/prakadesh-subramanian-37475b48/",
    },
    {
      img: m4,
      name: "Arjun Dhananjayan",
      work1: "Founder, 88GB",
      work2: "",
      link: "https://www.linkedin.com/in/arjundhananjayan/?originalSubdomain=in",
    },
    {
      img: m5,
      name: "Dr C.R. Rene Robin",
      work1: "Professor & Dean (Innovation)",
      work2: "Sri Sairam Engineering College",
      link: "https://www.linkedin.com/in/dr-c-r-rene-robin-52708322/",
    },
    {
      img: m6,
      name: "Muthukumaraswamy DPK",
      work1: "CEO- KrutiBimb Concepts",
      work2: "",
      link: "https://www.linkedin.com/in/muthukumaraswamy-dpk-ab8a984/",
    },
    {
      img: m7,
      name: "Ashwin B",
      work1: "Printed Circuit Board Designer",
      work2: "NGP Websmart Pvt.Ltd",
      link: "https://www.linkedin.com/in/aswin-kumar-shapers/",
    },
    {
      img: m8,
      name: "Raguraj Rajendran",
      work1: "Firmware Engineer - NGP",
      work2: "Websmart Pvt.Ltd",
      link: "https://www.linkedin.com/in/raguraj-rajendran-548769200/?originalSubdomain=in",
    },
  ];

  const Store2 = [
    {
      img: m9,
      name: "Sathish S",
      work1: "Founder & CEO, Otomatiks",
      work2: "",
      link: "https://www.linkedin.com/in/sathish-s-015613187/",
    },
    {
      img: m10,
      name: "Poornima T M",
      work1: "CEO, Penthusiasts",
      work2: "",
      link: "https://www.linkedin.com/in/poornima-t-m-895b9095/",
    },
    {
      img: m11,
      name: "Ponraj S",
      work1: "Assistant Engineer, VITTBI",
      work2: "",
      link: "https://www.linkedin.com/in/ponraj-selvaraj-leo/",
    },
    {
      img: m12,
      name: "Dr.Shamili Thirumaran",
      work1: "Co - Founder & COO, Medzinity",
      work2: "",
      link: "https://www.linkedin.com/in/drshamilithiru/",
    },
    {
      img: m13,
      name: "Anbumathi M",
      work1: "Business Strategist, in",
      work2: "DERMA Medical Devices",
      link: "https://www.linkedin.com/in/anbumathi/",
    },
    {
      img: m14,
      name: "Samuel Tensingh",
      work1: "Associate Lecturer",
      work2: "University of Sydney, Australia",
      link: "https://www.linkedin.com/in/samueltensingh/",
    },
    {
      img: m15,
      name: "Lavanya G",
      work1: "Co founder, CMO IDEEZ",
      work2: "career private limited",
      link: "https://www.linkedin.com/in/lavanya-gopal-028a55186/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      img: m16,
      name: " Dr Ponmurugan P",
      work1: "Associate Dean Meenakshi",
      work2: "Academy of Higher Education and Research",
      link: "https://www.linkedin.com/in/ponmurugan-panneer-selvam-3a599429/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  ];

  const Main = () => {
    return (
      <div className="flex flex-wrap gap-4 md:gap-8 justify-center">
        {Store1.map((item, index) => (
          <div
            key={index}
            className="h-auto w-full md:w-[450px] p-4 bg-white rounded-xl flex flex-col md:flex-row items-center shadow-lg"
          >
            <div className="md:w-1/3 p-4">
              <img
                src={item.img}
                alt={item.name}
                className="w-full h-auto rounded-full md:rounded-xl"
              />
            </div>
            <div className="md:w-2/3 mt-3 md:mt-0 md:ml-6 text-center md:text-left">
              <h1 className="text-black text-xl font-bold">{item.name}</h1>
              <p className="text-black font-semibold text-sm">{item.work1}</p>
              <p className="text-black font-semibold text-sm">{item.work2}</p>
              <div className="flex justify-center md:justify-start p-2">
                <a
                  href={item.link}
                  className="block mt-3 md:mt-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={lin}
                    alt="LinkedIn"
                    className="h-10 md:h-9"
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
        {Store2.map((item, index) => (
          <div
            key={index}
            className="h-auto w-full md:w-[450px] p-4 bg-white rounded-xl flex flex-col md:flex-row items-center shadow-lg"
          >
            <div className="md:w-1/3 p-4">
              <img
                src={item.img}
                alt={item.name}
                className="w-full h-auto rounded-full md:rounded-xl"
              />
            </div>
            <div className="md:w-2/3 mt-3 md:mt-0 md:ml-6 text-center md:text-left">
              <h1 className="text-black text-xl font-bold">{item.name}</h1>
              <p className="text-black font-semibold text-sm">{item.work1}</p>
              <p className="text-black font-semibold text-sm">{item.work2}</p>
              <div className="flex justify-center md:justify-start p-2">
                <a
                  href={item.link}
                  className="block mt-3 md:mt-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={lin}
                    alt="LinkedIn"
                    className="h-10 md:h-9"
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen py-16 px-4 md:px-8">
      <div className="flex justify-center mt-24">
        <h1 className="bg-white rounded-lg w-3/6 text-black px-3 py-4 text-center text-3xl md:text-5xl font-bold shadow-lg">
          Mentors
        </h1>
      </div>
      <div className="mt-8">
        <Main />
      </div>
    </div>
  );
};

export default Mentors;
