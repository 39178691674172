import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Tab1 from "./home/Tab1";
import About from "./about/About";
import Contact from "./contact/Contact";
import Tries from "./Initiatives/Tries";
import Chapters from "./chapters/Chapters";
import Coimbatore from "./chapters/Coimbatore";
import Ranipet from "./chapters/Ranipet";
import Tiruchirappalli from "./chapters/Tiruchirappalli";
import Vellore from "./chapters/Vellore";
import Media from "./media/Media";
import Startup from "./media/Startup";
import Portfolio from "./portfolio/Portfolio";
import DotstoLetters from "./media/companies/DotstoLetters";
import Jeevan from "./media/companies/Jeevan";
import Matrix from "./media/companies/Matrix";
import Eternals from "./media/companies/Eternals";
import NavFoot from "./body/NavFoot";
import Mentors from "./portfolio/Mentors";
import Partners from "./portfolio/Partners";
import Firstedition from "./portfolio/NewsLetter/Firstedition";
import Secondediton from "./portfolio/NewsLetter/Secondediton";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<NavFoot />}>
            <Route index element={<Tab1 />} />
            <Route path="/About" element={<About />} />
            <Route path="/About" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Tries" element={<Tries />} />
            <Route path="/Chapters" element={<Chapters />} />
            <Route path="/Coimbatore" element={<Coimbatore />} />
            <Route path="/Ranipet" element={<Ranipet />} />
            <Route path="/Tiruchirappalli" element={<Tiruchirappalli />} />
            <Route path="/Vellore" element={<Vellore />} />
            <Route path="/Media" element={<Media />} />
            <Route path="/Startup" element={<Startup />} />
            <Route path="/DotstoLetters" element={<DotstoLetters />} />
            <Route path="/Jeevan" element={<Jeevan />} />
            <Route path="/Matrix" element={<Matrix />} />
            <Route path="/Eternals" element={<Eternals />} />
            <Route path="/Portfolio" element={<Portfolio />} />
            <Route path="/Mentors" element={<Mentors />} />
            <Route path="/Partners" element={<Partners />} />
            <Route path="/Edition1" element={<Firstedition />} />
            <Route path="/Edition2" element={<Secondediton />} />


          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
