import React from 'react';
import Navbar from '../body/Navbar';
import Footer from '../body/Footer';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 min-h-screen">
      {/* <Navbar /> */}
      <div className="flex flex-col justify-center items-center h-full">
        <div className="max-w-4xl mt-44 p-8 bg-white shadow-2xl rounded-xl transform transition-all duration-300 hover:scale-105">
          <h1 className="text-5xl font-extrabold text-center text-gray-800 mb-6">ABOUT US</h1>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            <span className="font-bold">Entreovert People Association</span> is a non-profit organization located in Vellore, Tamil Nadu, India. Established on October 21st, 2022, our mission is to empower young entrepreneurs in Tier-II and III cities across India by fostering their innovative ideas and providing them with the necessary resources and mentorship. Our platform connects aspiring entrepreneurs with startup opportunities and angel investors, creating a supportive network for growth and success.
          </p>
          <div className="text-center">
            <Link to ="/Contact">
            <button className="px-6 py-3 bg-purple-600 text-white font-semibold rounded-lg shadow-md hover:bg-purple-700 transition-all duration-300">
              Get in Touch
            </button>
            </Link>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default About;
