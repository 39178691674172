import React from "react";
import Navbar from "../../body/Navbar";
import Footer from "../../body/Footer";

const Eternals = () => {
  const Points = (item) => {
    return (
      <div key={item.id}>
        <div className="">
          <div>
            <h1 className=" text-black pl-14 py-5 text-[45px] font-black">
              Eternals
            </h1>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Mordern Housing Solution
            </h1>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              <br />
              Myself,{" "}
              <spam className="font-bold text-yellow-500 text-[27px]">
                Namashivayam.R.R.
              </spam>
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              After Completing my UG, For the past 2 years, I am actively
              involved in Paying guest business
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Product
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              We Provide Alternate Solution for Housing, That' s Cost efficient
              an Befecial to our Clients.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Location
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Currrectly we Operate only in Tambaram, Serving only Bachelors we
              would like to expand it to families
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Achievements
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              We have served nearly 350 Bachelors Housing requirements in the
              past 2 Years. Achieving Significant amount of revenue.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Customers
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              Our clients are Mostly working Population.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Identified Problem
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              People ' s value of Investment made in Apartment is decreasing,
              locks them to the location permanently and Its an huge Investment.
            </p>
            <h1 className=" text-black pl-14 pt-10 pb-5 text-[25px] font-black">
              Solution
            </h1>
            <p className="text-gray-600 font-semibold px-14 text-justify ">
              We Wil offer an PG Kind of Housing solution to Families, with
              extensive amenities, to cater the needs of families
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200 h-[1400px] w-full ">
      {/* <div className="">
        <Navbar />
      </div> */}
      <div className="pt-[180px]">
        <div>
          <Points />
        </div>
      </div>
      {/* <div className="relative top-[120px] w-[100%] ">
        <Footer />
      </div> */}
    </div>
  );
};

export default Eternals;
